import React from 'react';
import './dropdown.css';
import {useTheme } from '@mui/material/styles';
// assets
// import { IconChevronDown } from '@tabler/icons-react';

const Dropdown = ({ header, children }) => {
	const theme = useTheme();
	const chevron = (
		<svg
			version='1.0'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 448 512'
			aria-hidden='true'
			fill='#231f20'
		>
			<g id='chevron-down1_layer'>
				<path
					d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'
				></path>
			</g>
		</svg>
	);
	return (
		<div className='dropdown'  style={{'--hover-color':theme.palette.primary.main}}>
			{/* <!-- The button that will be used as the dropdown trigger --> */}
			<button>
				<span className='whitespace-nowrap'>{header}</span>
				<span className='icon-container'>{chevron}</span>
			</button>

			{/* <!-- The dropdown content --> */}
			<div className='dropdown-content'>{children}</div>
		</div>
	);
};

export default Dropdown;
