
// Function to find if password contains special characters
export function isPasswordContainSpecialCharacters(password) {
  const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/;
  return specialCharactersRegex.test(password);
}

// Function to find if password contains numbers
export function isPasswordContainNumbers(password) {
  const numbersRegex = /\d/;
  return numbersRegex.test(password);
}

// Function to find if password contains uppercase letters
export function isPasswordContainUppercaseLetters(password) {
  const uppercaseRegex = /[A-Z]/;
  return uppercaseRegex.test(password);
}

// Function to find if password contains lowercase letters
export function isPasswordContainLowercaseLetters(password) {
  const lowercaseRegex = /[a-z]/;
  return lowercaseRegex.test(password);
}
