import { useState } from 'react';
import {useTheme} from '@mui/material/styles';
// assets
import { IconChevronDown } from '@tabler/icons-react';
import { IconChevronUp } from '@tabler/icons-react';

import './collapsible.css';
function Collapsible({ children, open }) {
  return (
    <div
    className={`collapsible ${open ? 'open' : ''}`}
    style={{ '--height': open ? 'auto' : '0' }}
    >
      {children}
    </div>
  );
}

function CollapseComponent({ header, children }) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prev) => !prev);
  const theme = useTheme();

  return (
    <div style={{'--hover-color':theme.palette.primary.main}}>
      <button
        className={`flex align-center justify-between collapse-header ${open ? 'open' : ''}`}
        style={{ '--rotate': open ? '180deg' : '0' }}
        onClick={toggle}
        aria-expanded={open}
        aria-label="Toggle collapsible"
      >
        {header}
        <span>
          {open ? <IconChevronUp size={24} /> : <IconChevronDown size={24} />}
        </span>
      </button>
      <Collapsible open={open}>{children}</Collapsible>
    </div>
  );
}

export default CollapseComponent;
