import React, { useState, useMemo, useEffect } from 'react';

// mui-material
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

// project import
import iconList from 'src/constants/icons';

const PricingCard = ({
	title,
	tagline,
	price,
	actions,
	packageFeatures,
	featureHeading,
	packagePricingVariations,
	pricingHeading,
	icon,
	cardsx,
}) => {
	const [selectedIcon, setSelectedIcon] = useState('');
	useEffect(() => {
		if (icon) {
			setSelectedIcon(icon);
		}
	}, [icon]);

	const selectedIconObj = useMemo(() => {
		if (!selectedIcon) return null;
		const selectedObj = iconList.find((icon) => icon.name === selectedIcon);
		return selectedObj;
	}, [selectedIcon]);

	return (
		<Card
			sx={{
				minHeight: 600,
				height: '100%',
				border: '2px solid',
				borderColor: 'primary.main',
				...cardsx,
			}}
		>
			<CardHeader
				title={
					<Typography variant='h3' fontWeight={500}>
						{title}
					</Typography>
				}
				subheader={
					<Typography
						variant='subtitle1'
						component='p'
						color='text.secondary'
						fontWeight={400}
						sx={{ letterSpacing: '0.6px', minHeight: 50 }}
					>
						{tagline}
					</Typography>
				}
			/>
			<CardContent>
				{/* wrap both typographies in a flex box */}
				{pricingHeading ? (
					<Typography>{pricingHeading}</Typography>
				) : (
					<Typography mt={3}></Typography>
				)}
				<Stack
					direction='row'
					gap={2}
					alignItems='center'
					sx={{ mt: Number(packagePricingVariations[0]?.price) <= 0 && '26px' }}
				>
					<Typography variant='h2' color='primary' fontWeight={500} sx={{position:'relative'}}>
						$
						{Number(packagePricingVariations[0]?.price)
							? Number(packagePricingVariations[0]?.price).toLocaleString()
							: 0}
						/
						{packagePricingVariations[0]?.price > 0 ? (
							<Typography
								variant='caption'
								sx={{ fontSize: '1rem', fontWeight: 700 }}
							>
								{packagePricingVariations[0].type}
							</Typography>
						) : (
							'-'
						)}
						{title === 'Professional' &&
							(
								<Typography sx={{position:'absolute', left:0,bottom:-10}} ml={1} variant='caption' fontSize={'10px'} fontWeight={700}>
								(Billed Annually)
								</Typography>
							)
						}
					</Typography>
				</Stack>
				{/* section for actions that are buttons */}
				<Stack mt={2}>{actions}</Stack>
				<Typography paragraph mt={2} fontWeight={700} ml={'5px'}>
					{featureHeading}
				</Typography>
				<List>
					{packageFeatures.map((feature) => {
						if (feature.title === 'Cost scales up with users - never more than $3999') {
							return (
								<ListItem key={feature.title}>
									<ListItemText sx={{'& .MuiListItemText-primary':{fontWeight:600}}} primary={feature.title} />
								</ListItem>
							);
						}
						return (
							<ListItem key={feature.title}>
								{selectedIcon && (
									<ListItemIcon>{selectedIconObj[selectedIcon]}</ListItemIcon>
								)}
								<ListItemText primary={feature.title} />
							</ListItem>
						);
					})}
				</List>
			</CardContent>
		</Card>
	);
};

export default PricingCard;
