import { createContext, useContext, useState } from 'react';

const initialValues = {
	packages: [],
	setPackages: () => {},
	isLoading: false,
	setIsLoading: () => {},
	isAuthorized: false,
	setIsAuthorized: () => {},
};
const PackagesContext = createContext(initialValues);

export const usePackagesContext = () => {
	const context = useContext(PackagesContext);
	if (context === undefined) {
		throw new Error('usePackages must be used within a PackagesProvider');
	}
	return context;
};

function PackagesProvider({ children }) {
	const [packages, setPackages] = useState([]);
	const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
	const values = {
		packages,
		setPackages,
		isAuthorized,
    setIsAuthorized,
    isLoading,
    setIsLoading
	};

	return (
		<PackagesContext.Provider value={values}>
			{children}
		</PackagesContext.Provider>
	);
}

export default PackagesProvider;
