import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

// mui-material
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

// project components
import Header from './header';
// import Header2 from './header2';
import Footer from './footer';

// bg
import Background from 'src/assets/hero-bg.jpg';

const Layout = () => {
	return (
		<Fragment>
			<Box
				sx={{
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center center',
					backgroundSize: 'cover',
					backgroundImage: `url(${Background})`,
				}}
			>
				<Header />
				{/* <Header2 /> */}
				<Container maxWidth={'xl'} sx={{ pb: 12 }}>
					<Outlet />
				</Container>
			</Box>
			<Footer />
		</Fragment>
	);
};

export default Layout;
