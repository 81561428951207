import { Toaster } from 'sonner';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// theme
import ThemeProvider from 'src/theme';

// routes
import Routes from './routes';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchInterval: false,
			refetchIntervalInBackground: false,
			retry: false,
		},
	},
});
function App() {
	return (
		<ThemeProvider>
			<Toaster position='top-right' richColors />
			<QueryClientProvider client={queryClient}>
				<Routes />
			</QueryClientProvider>
		</ThemeProvider>
	);
}

export default App;
