export const baseURL = process.env.REACT_APP_WEB_BASE_URL
export const Home = `${baseURL}/`
export const Contact = `${baseURL}/contact-us`
export const Enterprise = `${baseURL}/enterprise`
export const Product = {
	gaForStudents: `${baseURL}/ga-for-students`,
	ourPlatform: `${baseURL}/our-platform`,
	buildingInGA: `${baseURL}/build`,
	dataIntegeration: `${baseURL}/integration`,
	reportingInGA: `${baseURL}/reporting-in-ga`,
};
export const Resources = {
	quickStartGuide: `${baseURL}/quick-start-guide`,
	blogs: `${baseURL}/en/ga_universe`,
	events: `${baseURL}/events`,
	digitalTransformation: `${baseURL}/digital-transformation`,
	docs: `${baseURL}/digital-transformation`,
  pricing: `${baseURL}/#`
};
export const AboutUs = {
  ourVision: `${baseURL}/our-vision`,
  ourMission: `${baseURL}/our-mission`,
  ourHistory: `${baseURL}/our-history`,
  partner: `${baseURL}/partners`
};

export const joinWaitingList =
	`${baseURL}/ts-new-landing-page#csymnewsletter`;