import React, { Fragment, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

// @mui-materials
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// project imports
import LoaderButton from 'src/components/loaderButton';
import { GhoastButton } from '../../components/button';
import { unProtectedFetch as axios } from 'src/utils/axios';
import { otp as otpRoute } from 'src/constants/api';

const OTP = ({ setIsEmailVerified, email, handleCloseVerification, setVerifiedEmail }) => {
	const interval = 60;
	const [resend, setResend] = useState(interval);
	const [otp, setOtp] = useState('');

	const { isPending: isFirstOTPSending } = useQuery({
		queryKey: ['verifyEmail'],
		queryFn: async () => {
			const response = await axios.post(otpRoute.one_time_password, { email });
			return response.data;
		},
		onSuccess: () => {
			toast.info('Please check your email for OTP');
		},
	});

	const { isPending: isOTPVerifying, mutate: verifyEmail } = useMutation({
		mutationFn: async () => {
			const response = await axios.post(otpRoute.otp_verify, { email, otp });
			return response.data;
		},
		onSuccess: () => {
			toast.success('OTP Verified');
			setIsEmailVerified(true);
			setVerifiedEmail(email);
			handleCloseVerification();
		},
	});

	const { isPending: isOTPResending, mutate: resendOTP } = useMutation({
		mutationFn: async () => {
			const response = await axios.post(otpRoute.otp_resend, { email });
			return response.data;
		},
		onSuccess: () => {
			toast.info('Please check your email for OTP');
		},
	});

	const isOTPSending = isFirstOTPSending || isOTPResending || isOTPVerifying;

	useEffect(() => {
		let timer;
		timer = setTimeout(() => {
			if (resend > 0 && !isOTPSending) setResend((resend) => resend - 1);
		}, 1000);
		return () => clearTimeout(timer);
	}, [resend, isOTPSending]);

	const handleReset = () => {
		resendOTP();
		setOtp('');
		setResend(interval);
	};

	return (
		<Fragment>
			<Typography
				id='modal-modal-title'
				variant='h6'
				component='h2'
				align='center'
			>
				Verification
			</Typography>
			<Typography id='modal-modal-description' align='center' sx={{ mt: 2 }}>
				Please enter the 6-digit OTP sent to your email address
			</Typography>
			<Stack justifyContent='center' alignItems='center' sx={{ my: 2 }}>
				<OtpInput
					shouldAutoFocus
					inputStyle={{ width: '3rem', height: '3rem' }}
					value={otp}
					onChange={setOtp}
					numInputs={6}
					renderSeparator={<span>-</span>}
					renderInput={(props) => <input {...props} type='number' />}
				/>
			</Stack>
			{/* didn't receive code? */}
			<Box sx={{ mb: 4 }}>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='center'
					gap={0.5}
				>
					<Typography variant='body2' color='text.secondary' align='center'>
						Didn't receive code?
					</Typography>
					<GhoastButton
						size='small'
						disabled={resend > 0}
						onClick={handleReset}
					>
						resend code
					</GhoastButton>
				</Stack>
				{/* resend duration */}
				<Typography variant='body2' color='text.secondary' align='center'>
					Can Resend code in 00:{resend < 10 ? `0${resend}` : resend}
				</Typography>
			</Box>
			{/* verify button */}
			<LoaderButton
				fullWidth
				disableRipple
				variant='contained'
				size='large'
				disabled={isOTPSending}
				isLoading={isOTPSending}
				onClick={() => verifyEmail()}
			>
				Continue
			</LoaderButton>
		</Fragment>
	);
};

export default OTP;
