import React from 'react';
import { Routes, Route } from 'react-router-dom';

// pages
import SignUp from 'src/pages/signUp';
import Pricing from 'src/pages/pricing';
import Layout from 'src/layout';
import PackagesProvider from 'src/context/packages';

const RouterPage = () => {
	return (
		<PackagesProvider>
			<Routes>
				{/* <Route path='/' element={<Navigate to='/pricing' replace />} /> */}
				<Route path='/' element={<Layout />}>
					<Route index element={<Pricing />} />
				</Route>
				<Route path='/sign-up' element={<SignUp />} />
			</Routes>
		</PackagesProvider>
	);
};

export default RouterPage;
