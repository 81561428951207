import React from 'react';

// material-ui
import { Stack, Typography, CircularProgress } from '@mui/material';
// project imports
import { StyledButton as Button } from './button'

const LoaderButton = ({ isLoading = false, children, ...other }) => {
	return (
		<Button disabled={isLoading} {...other}>
			{isLoading ? (
				<Stack direction='row' alignItems='center'>
					<CircularProgress size={29} color='dark' />
					<Typography variant='subtitle1' component='span' sx={{ ml: 1 }}>
						Loading...
					</Typography>
				</Stack>
			) : (
				children
			)}
		</Button>
	);
};
export default LoaderButton;
