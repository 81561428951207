import React, { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// mui-material
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';

// project imoprt
import PricingCard from './pricingCard';
import { packages as packageRoute } from 'src/constants/api';
import { unProtectedFetch as axios } from 'src/utils/axios';
import { StyledButton as Button } from 'src/components/button';
import { Contact } from 'src/constants/externalPagesLinks';
import { useTheme } from '@mui/material/styles';
// import { usePackagesContext } from 'src/context/packages';
// import Authorizebutton from './authorizebutton';
// import usePackages from 'src/hooks/usePackages';
// import FAQs from './faqs';

const Pricing = () => {
	const { data: packages, isLoading: isPackagesLoading } = useQuery({
    queryKey: ['packages'],
    queryFn: async () => {
      const response = await axios.get(packageRoute.get);
      return response.data;
    },
  });

	// const {
	// 	isAuthorized,
	// 	isLoading: isPackagesLoading,
	// 	packages,
	// } = usePackagesContext();
	// usePackages();

	const activePackages = useMemo(() => {
		if (!packages) return null;
		return packages?.filter((item) => item.status === 'active');
	}, [packages]);

	// if (!isAuthorized) return <Authorizebutton />;
	const noPackages = (
		<Card>
			<CardContent>
				<Typography variant='h5' align='center'>
					There are no packages to show.
				</Typography>
			</CardContent>
		</Card>
	);
	const packagesSekeletonLoader = (
		<Stack direction='row' spacing={2}>
			<Skeleton
				variant='rectangular'
				height={600}
				sx={{ flex: 1, borderRadius: 1 }}
			/>
			<Skeleton
				variant='rectangular'
				height={600}
				sx={{ flex: 1, borderRadius: 1 }}
			/>
			<Skeleton
				variant='rectangular'
				height={600}
				sx={{ flex: 1, borderRadius: 1 }}
			/>
		</Stack>
	);
	const conditionalRendering = (isPackagesLoading, activePackages) => {
		if (isPackagesLoading)
			return (
				<Grid item xs={12}>
					{packagesSekeletonLoader}
				</Grid>
			);
		if (activePackages && activePackages.length === 0)
			return (
				<Grid item xs={12}>
					{noPackages}
				</Grid>
			);
		if (activePackages && activePackages.length > 0) {
			return activePackages.map((item) => (
				<Grid item xs={12} sm={6} md={4} key={item._id}>
					<PricingCard
						title={item.name}
						tagline={item.tagline}
						price={item.price}
						packageFeatures={item.packageFeatures}
						featureHeading={item.featureHeading}
						pricingHeading={item.pricingHeading}
						packagePricingVariations={item.packagePricingVariations}
						icon={item.icon}
						cardsx={{
							backgroundColor: 'rgb(255 255 255)',
							boxShadow: '0 0 0 1px rgb(0 0 0 / 5%)',
							borderRadius: '8px',
						}}
						actions={<Actions pricing={item} />}
					/>
				</Grid>
			));
		}
	};
	return (
		<Stack
			justifyContent={
				activePackages && activePackages.length !== 0 ? 'center' : 'flex-start'
			}
			alignItems='center'
			minHeight={activePackages && activePackages.length !== 0 && '100vh'}
		>
			{activePackages && activePackages.length !== 0 && (
				<Fragment>
					<Typography variant='h2' fontWeight={700} mt={3} textAlign={'center'} maxWidth={'1250px'} mx={'auto'} textTransform={'capitalize'}>
						Simple licensing, full cost certainty
					</Typography>
					<Typography variant='h5' color='text.secondary' mb={6}>
						We enable you to build your future on one platform
					</Typography>
				</Fragment>
			)}
			<Stack width='100%' maxWidth='lg'>
				<Grid
					container
					spacing={3}
					alignItems='stretch'
					justifyContent='center'
				>
					{conditionalRendering(isPackagesLoading, activePackages)}
				</Grid>
			</Stack>
			{/* <Typography variant='h3' mt={6}>
				Frequently asked questions
			</Typography>
			<Stack mt={3} width='100%' maxWidth='lg'>
				<FAQs />
			</Stack> */}
		</Stack>
	);
};
const Actions = ({ pricing }) => {
	const navigate = useNavigate();
	const { contactUs, selfHosting, slUrl } = pricing;
	const theme = useTheme();
	const firstButton =
		contactUs === 'enabled' ? (
			<Button
				variant='contained'
				fullWidth
				size='large'
				sx={{
					borderRadius: 1.7,
					'&.MuiTypography-root.MuiTypography-inherit.MuiLink-root': {
						color: 'white !important',
					},
				}}
				LinkComponent={Link}
				href={Contact}
			>
				Contact Us
			</Button>
		) : (
			<Button
				variant='contained'
				fullWidth
				size='large'
				sx={{ borderRadius: 1.7 }}
				onClick={() =>
					navigate('/sign-up', { state: { package: pricing._id } })
				}
			>
				Try Cloud
			</Button>
		);

	return (
		<Stack direction='row' gap={2}>
			{firstButton}
			{selfHosting === 'enabled' && (
				<Button
					LinkComponent={'a'}
					href={slUrl}
					variant='outlined'
					fullWidth
					size='large'
					sx={{
						borderRadius: 1.7,
						color: `${theme.palette.primary.main} !important`,
						'&:hover': {
							color: `white !important`,
						},
					}}
				>
					Self-host
				</Button>
			)}
		</Stack>
	);
};
export default Pricing;
