import React from 'react';

// @mui-materials
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import config from 'src/config';
const CreateAccountCard = () => {
	const { palette } = useTheme();

	return (
		<Stack
			sx={{
				'& a': {
					color: `${palette.primary.main} !important`,
				},
				'& a:hover': {
					textDecoration: 'underline !important',
				},
				'& a.policy-link': {
					color: `${palette.text.secondary} !important`,
					textDecorationColor: `${palette.text.secondary} !important`,
					fontSize: '0.9rem !important',
					margin: '0 0.5rem',
				},
			}}
		>
			<Typography variant='body1' color='text.secondary' align='center'>
				Already have an account? <Link href={config.ga_app_URL} target='_blank'>Log In</Link>
			</Typography>
			<Typography variant='body1' align='center' sx={{ mt: 2 }}>
				<Link href={config.privacyPolicyURL} className='policy-link'>
					Privacy Policy
				</Link>
				<Link href={config.termsAndConditionsURL} className='policy-link'>
					Terms & Conditions
				</Link>
			</Typography>
		</Stack>
	);
};

export default CreateAccountCard;
