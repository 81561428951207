import React from 'react';
import * as links from '../constants/externalPagesLinks';
const Footer = () => {
	return (
		<footer className='footer'>
			<div className='container-fluid'>
				<div className='row-fluid-wrapper'>
					<div className='row-fluid'>
						<div
							className='span12 widget-span widget-type-cell '
							data-widget-type='cell'
							data-x='0'
							data-w='12'
						>
							<div className='row-fluid-wrapper row-depth-1 row-number-1 footer-row-0-background-color dnd-section footer-row-0-padding footer-row-0-force-full-width-section'>
								<div className='row-fluid '>
									<div
										className='span3 widget-span widget-type-cell dnd-column'
										data-widget-type='cell'
										data-x='0'
										data-w='3'
									>
										<div className='row-fluid-wrapper row-depth-1 row-number-2 dnd-row'>
											<div className='row-fluid '>
												<div
													className='span12 widget-span widget-type-custom_widget widget_1697698174857-flexbox-positioning dnd-module'
													data-widget-type='custom_widget'
													data-x='0'
													data-w='12'
												>
													<div
														id='hs_cos_wrapper_widget_1697698174857'
														className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-linked_image'
														data-hs-cos-general-type='widget'
														data-hs-cos-type='module'
													>
														<span
															id='hs_cos_wrapper_widget_1697698174857_'
															className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_linked_image'
															data-hs-cos-general-type='widget'
															data-hs-cos-type='linked_image'
														>
															<img
																className='header__logo-image'
																src={`${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=260&amp;height=41&amp;name=GA_universe-1-1.png`}
																loading='eager'
																alt='GA_Universe'
																height='41'
																width='260'
																srcSet={`${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=130&amp;height=21&amp;name=GA_universe-1-1.png 130w, "${links.baseURL}"/hs-fs/hubfs/GA_universe-1-1.png?width=260&amp;height=41&amp;name=GA_universe-1-1.png 260w, ${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=390&amp;height=62&amp;name=GA_universe-1-1.png 390w, ${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=520&amp;height=82&amp;name=GA_universe-1-1.png 520w, ${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=650&amp;height=103&amp;name=GA_universe-1-1.png 650w, ${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=780&amp;height=123&amp;name=GA_universe-1-1.png 780w`}
																sizes='(max-width: 260px) 100vw, 260px'
															/>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div className='row-fluid-wrapper row-depth-1 row-number-3 dnd-row'>
											<div className='row-fluid '>
												<div
													className='span12 widget-span widget-type-custom_widget dnd-module'
													data-widget-type='custom_widget'
													data-x='0'
													data-w='12'
												>
													<div
														id='hs_cos_wrapper_widget_1697698201600'
														className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text'
														data-hs-cos-general-type='widget'
														data-hs-cos-type='module'
													>
														<span
															id='hs_cos_wrapper_widget_1697698201600_'
															className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text'
															data-hs-cos-general-type='widget'
															data-hs-cos-type='rich_text'
														>
															<p>
																Our journey began with a clear observation: The
																transformative benefits that low-code platforms
																were bringing to businesses, both large and
																small. We recognized the immense potential these
																platforms held in enabling companies to craft
																integrated digital solutions in-house.
															</p>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className='span3 widget-span widget-type-cell dnd-column'
										data-widget-type='cell'
										data-x='3'
										data-w='3'
									>
										<div className='row-fluid-wrapper row-depth-1 row-number-4 dnd-row'>
											<div className='row-fluid '>
												<div
													className='span12 widget-span widget-type-custom_widget dnd-module'
													data-widget-type='custom_widget'
													data-x='0'
													data-w='12'
												>
													<div
														id='hs_cos_wrapper_widget_1697698509851'
														className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header'
														data-hs-cos-general-type='widget'
														data-hs-cos-type='module'
													>
														<span
															id='hs_cos_wrapper_widget_1697698509851_'
															className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header'
															data-hs-cos-general-type='widget'
															data-hs-cos-type='header'
														>
															<h5>Main Links</h5>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div className='row-fluid-wrapper row-depth-1 row-number-5 dnd-row'>
											<div className='row-fluid '>
												<div
													className='span12 widget-span widget-type-custom_widget dnd-module'
													data-widget-type='custom_widget'
													data-x='0'
													data-w='12'
												>
													<div
														id='hs_cos_wrapper_widget_1697699407941'
														className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu'
														data-hs-cos-general-type='widget'
														data-hs-cos-type='module'
													>
														<span
															id='hs_cos_wrapper_widget_1697699407941_'
															className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu'
															data-hs-cos-general-type='widget'
															data-hs-cos-type='menu'
														>
															<div
																id='hs_menu_wrapper_widget_1697699407941_'
																className='hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical'
																role='navigation'
																data-sitemap-name='default'
																data-menu-id='140939081588'
																aria-label='Navigation Menu'
															>
																<ul role='menu'>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.AboutUs.ourVision}
																			role='menuitem'
																		>
																			Our Vision
																		</a>
																	</li>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.AboutUs.ourMission}
																			role='menuitem'
																		>
																			Our Mission
																		</a>
																	</li>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.AboutUs.ourHistory}
																			role='menuitem'
																		>
																			Our History
																		</a>
																	</li>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.Resources.events}
																			role='menuitem'
																		>
																			Events
																		</a>
																	</li>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.Resources.blogs}
																			role='menuitem'
																		>
																			Blog
																		</a>
																	</li>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.Contact}
																			role='menuitem'
																		>
																			Contact Us
																		</a>
																	</li>
																</ul>
															</div>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className='span3 widget-span widget-type-cell dnd-column'
										data-widget-type='cell'
										data-x='6'
										data-w='3'
									>
										<div className='row-fluid-wrapper row-depth-1 row-number-6 dnd-row'>
											<div className='row-fluid '>
												<div
													className='span12 widget-span widget-type-custom_widget dnd-module'
													data-widget-type='custom_widget'
													data-x='0'
													data-w='12'
												>
													<div
														id='hs_cos_wrapper_module_1697699541390'
														className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header'
														data-hs-cos-general-type='widget'
														data-hs-cos-type='module'
													>
														<span
															id='hs_cos_wrapper_module_1697699541390_'
															className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header'
															data-hs-cos-general-type='widget'
															data-hs-cos-type='header'
														>
															<h5>Product</h5>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div className='row-fluid-wrapper row-depth-1 row-number-7 dnd-row'>
											<div className='row-fluid '>
												<div
													className='span12 widget-span widget-type-custom_widget dnd-module'
													data-widget-type='custom_widget'
													data-x='0'
													data-w='12'
												>
													<div
														id='hs_cos_wrapper_module_1697699545261'
														className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu'
														data-hs-cos-general-type='widget'
														data-hs-cos-type='module'
													>
														<span
															id='hs_cos_wrapper_module_1697699545261_'
															className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu'
															data-hs-cos-general-type='widget'
															data-hs-cos-type='menu'
														>
															<div
																id='hs_menu_wrapper_module_1697699545261_'
																className='hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical'
																role='navigation'
																data-sitemap-name='default'
																data-menu-id='140948111731'
																aria-label='Navigation Menu'
															>
																<ul role='menu'>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.Product.gaForStudents}
																			role='menuitem'
																		>
																			GA For Students
																		</a>
																	</li>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.Product.ourPlatform}
																			role='menuitem'
																		>
																			Our Platform
																		</a>
																	</li>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.Product.buildingInGA}
																			role='menuitem'
																		>
																			Building in GA
																		</a>
																	</li>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.Product.dataIntegeration}
																			role='menuitem'
																		>
																			Data Integration
																		</a>
																	</li>
																	<li
																		className='hs-menu-item hs-menu-depth-1'
																		role='none'
																	>
																		<a
																			href={links.Product.reportingInGA}
																			role='menuitem'
																		>
																			Reporting In GA
																		</a>
																	</li>
																</ul>
															</div>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className='span3 widget-span widget-type-cell dnd-column'
										data-widget-type='cell'
										data-x='9'
										data-w='3'
									>
										<div className='row-fluid-wrapper row-depth-1 row-number-8 dnd-row'>
											<div className='row-fluid '>
												<div
													className='span12 widget-span widget-type-custom_widget dnd-module'
													data-widget-type='custom_widget'
													data-x='0'
													data-w='12'
												>
													<div
														id='hs_cos_wrapper_module_1697699678144'
														className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header'
														data-hs-cos-general-type='widget'
														data-hs-cos-type='module'
													>
														<span
															id='hs_cos_wrapper_module_1697699678144_'
															className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header'
															data-hs-cos-general-type='widget'
															data-hs-cos-type='header'
														>
															<h5>Contact</h5>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div className='row-fluid-wrapper row-depth-1 row-number-9 dnd-row'>
											<div className='row-fluid '>
												<div
													className='span12 widget-span widget-type-custom_widget dnd-module'
													data-widget-type='custom_widget'
													data-x='0'
													data-w='12'
												>
													<div
														id='hs_cos_wrapper_widget_1697699837869'
														className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text'
														data-hs-cos-general-type='widget'
														data-hs-cos-type='module'
													>
														<span
															id='hs_cos_wrapper_widget_1697699837869_'
															className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text'
															data-hs-cos-general-type='widget'
															data-hs-cos-type='rich_text'
														>
															<div className='maincstmcont'>
																<div className='icondiv'>
																	<img src={`${links.baseURL}/hubfs/43578905/Mask%20group.svg`} alt='location'/>
																</div>
																<div className='infodiv'>
																	<p className='title'>Address</p>
																	<p className='details'>
																		Ghaida Tower, Jeddah, <br />
																		Saudi Arabia
																	</p>
																</div>
															</div>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='row-fluid-wrapper row-depth-1 row-number-10 footer-row-1-force-full-width-section dnd-section footer-row-1-padding footer-row-1-background-color'>
								<div className='row-fluid '>
									<div
										className='span12 widget-span widget-type-custom_widget dnd-module'
										data-widget-type='custom_widget'
										data-x='0'
										data-w='12'
									>
										<div
											id='hs_cos_wrapper_footer-module-4'
											className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module'
											data-hs-cos-general-type='widget'
											data-hs-cos-type='module'
										>
											<hr />
										</div>
									</div>
								</div>
							</div>

							<div className='row-fluid-wrapper row-depth-1 row-number-11 dnd-section footer-row-2-background-color footer-row-2-padding footer-row-2-force-full-width-section'>
								<div className='row-fluid '>
									<div
										className='span12 widget-span widget-type-cell dnd-column'
										data-widget-type='cell'
										data-x='0'
										data-w='12'
									>
										<div className='row-fluid-wrapper row-depth-1 row-number-12 dnd-row'>
											<div className='row-fluid '>
												<div
													className='span12 widget-span widget-type-cell dnd-column'
													data-widget-type='cell'
													data-x='0'
													data-w='12'
												>
													<div className='row-fluid-wrapper row-depth-1 row-number-13 dnd-row'>
														<div className='row-fluid '>
															<div
																className='span12 widget-span widget-type-custom_widget dnd-module'
																data-widget-type='custom_widget'
																data-x='0'
																data-w='12'
															>
																<div
																	id='hs_cos_wrapper_footer-module-10'
																	className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text'
																	data-hs-cos-general-type='widget'
																	data-hs-cos-type='module'
																>
																	<span
																		id='hs_cos_wrapper_footer-module-10_'
																		className='hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text'
																		data-hs-cos-general-type='widget'
																		data-hs-cos-type='rich_text'
																	>
																		<div style={{ textAlign: 'right' }}>
																			<p style={{ textAlign: 'center' }}>
																				Copyright © 2023, GA_Universe
																			</p>
																		</div>
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
