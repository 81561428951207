import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import DownloadDoneOutlinedIcon from '@mui/icons-material/DownloadDoneOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

const iconList = [
	{
		name: 'CircleOutlinedIcon',
		CircleOutlinedIcon: <CircleOutlinedIcon />,
	},
	{
		name: 'RadioButtonUncheckedOutlinedIcon',
		RadioButtonUncheckedOutlinedIcon: <RadioButtonUncheckedOutlinedIcon />,
	},
	{
		name: 'RadioButtonCheckedOutlinedIcon',
		RadioButtonCheckedOutlinedIcon: <RadioButtonCheckedOutlinedIcon />,
	},
	{
		name: 'CheckOutlinedIcon',
		CheckOutlinedIcon: <CheckOutlinedIcon />,
	},
	{
		name: 'DoneAllOutlinedIcon',
		DoneAllOutlinedIcon: <DoneAllOutlinedIcon />,
	},
	{
		name: 'TaskAltOutlinedIcon',
		TaskAltOutlinedIcon: <TaskAltOutlinedIcon />,
	},
	{
		name: 'DoneOutlineOutlinedIcon',
		DoneOutlineOutlinedIcon: <DoneOutlineOutlinedIcon />,
	},
	{
		name: 'DownloadDoneOutlinedIcon',
		DownloadDoneOutlinedIcon: <DownloadDoneOutlinedIcon />,
	},
	{
		name: 'RuleOutlinedIcon',
		RuleOutlinedIcon: <RuleOutlinedIcon />,
	},
	{
		name: 'ExpandLessOutlinedIcon',
		ExpandLessOutlinedIcon: <ExpandLessOutlinedIcon />,
	},
	{
		name: 'ExpandMoreOutlinedIcon',
		ExpandMoreOutlinedIcon: <ExpandMoreOutlinedIcon />,
	},
	{
		name: 'ChevronLeftOutlinedIcon',
		ChevronLeftOutlinedIcon: <ChevronLeftOutlinedIcon />,
	},
	{
		name: 'ChevronRightOutlinedIcon',
		ChevronRightOutlinedIcon: <ChevronRightOutlinedIcon />,
	},
	{
		name: 'DoubleArrowOutlinedIcon',
		DoubleArrowOutlinedIcon: <DoubleArrowOutlinedIcon />,
	},
	{
		name: 'ExpandCircleDownOutlinedIcon',
		ExpandCircleDownOutlinedIcon: <ExpandCircleDownOutlinedIcon />,
	},
	{
		name: 'UnfoldMoreOutlinedIcon',
		UnfoldMoreOutlinedIcon: <UnfoldMoreOutlinedIcon />,
	},
	{
		name: 'FirstPageOutlinedIcon',
		FirstPageOutlinedIcon: <FirstPageOutlinedIcon />,
	},
	{
		name: 'CheckCircleOutlineIcon',
		CheckCircleOutlineIcon: <CheckCircleOutlineIcon />,
	},
	{
		name: 'CheckCircleIcon',
		CheckCircleIcon: <CheckCircleIcon />,
	},
	{
		name: 'OfflinePinIcon',
		OfflinePinIcon: <OfflinePinIcon />,
	},
	{
		name: 'DomainVerificationIcon',
		DomainVerificationIcon: <DomainVerificationIcon />,
	},
	{
		name: 'DoneAllIcon',
		DoneAllIcon: <DoneAllIcon />,
	},
	{
		name: 'BookmarkAddedIcon',
		BookmarkAddedIcon: <BookmarkAddedIcon />,
	},
];

export default iconList;
