import React from 'react';

// material-ui
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const TooltipContentContainer = styled(Card)(({ theme }) => ({
	position: 'absolute',
	top: '50%',
	right: 0,
	transform: 'translate(100%,-50%)',
	width: '100%',
	maxWidth: '250px',
	// padding: theme.spacing(2),
	borderRadius: '10px',
	boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
	zIndex: 99,
	[theme.breakpoints.down('sm')]: {
		top: '100%',
		left: '50%',
		transform: 'translate(-50%, 0)',
		maxWidth: '100%',
	},
}));

export function Tooltip({ children }) {
	return (
		<Box position='relative' overflow={'visible'}>
			{children}
		</Box>
	);
}

export function ToolipContent({ children, show }) {
	return (
		<TooltipContentContainer style={{ display: show ? 'block' : 'none' }}>
			{children}
		</TooltipContentContainer>
	);
}
