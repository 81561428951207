import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
	lighter: '#d0edfa',
	light: '#72c8ef',
	main: '#14A3E5',
	dark: '#0c6289',
	darker: '#08415c',
	contrastText: '#FFF',
};

const SECONDARY = {
  lighter:"#d5b5ff",
  light: '#ac6cff',
  main: '#9747ff',
  dark: '#5b2b99',
  darker:"#3c1c66",
  contrastText: '#FFF',
};

const INFO = {
  lighter:"#afcbff",
  light: '#5f97ff',
  main: '#377DFF',
  dark: '#214b99',
  darker:"#163266",
  contrastText: '#FFF',
};

const SUCCESS = {
  lighter:"#afead0",
  light: '#60d5a1',
  main: '#38CB89',
  dark: '#227a52',
  darker:"#165137",
  contrastText: '#FFF',
};


const WARNING = {
  lighter:"#ffe199",
  light: '#FFCA64',
  main: '#FFB400',
  dark: '#E09E00',
  darker:"#664800",
  contrastText: '#FFF',
};
const ERROR = {
  lighter: "#ffb7b9",
  light: '#FF6166',
  main: '#FF4C51',
  dark: '#E04347',
  darker:"#992e31",
  contrastText: '#FFF',
};
const DARK = {
  lighter:"#aaaaaf",
  light: '#808086',
  main: '#2b2b36',
  dark: '#1a1a20',
  darker:"#09090b",
  contrastText: '#FFF',
}
const DEAD = {
  main: GREY[700],
  light: GREY[400],
  dark: GREY[900],
  contrast:'#FFF'
}
const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  dark: DARK,
  dead:DEAD,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
