import axios from 'axios';
import { toast } from 'sonner';
import config from '../config';

const getToken = () => localStorage.getItem(config.accessTokenName);
// const getToken = () => process.env.REACT_APP_BACKEND_SERVICE_TOKEN;

const unProtectedFetch = axios.create({
	baseURL: config.backendServiceBaseURL,
});

unProtectedFetch.interceptors.response.use(
	(response) => response,
	(error) => {
		const { response } = error;
		if (response && response?.data?.message) {
			toast.error(response.data.message);
		}
		return Promise.reject(error);
	}
);

const protectedFetch = axios.create({
	baseURL: config.backendServiceBaseURL,
});

protectedFetch.interceptors.request.use(
	(config) => {
		const authToken = 'Bearer ' + getToken();
		config.headers.Authorization = authToken;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// protectedFetch.interceptors.response.use(
// 	(response) => response,
// 	(error) => {
// 		return Promise.reject(error);
// 	}
// );

export { unProtectedFetch, protectedFetch };
