import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'inherit !important',
  border: `1px solid ${theme.palette.primary.main}`,
	'&:hover, &:focus': {
		backgroundColor: theme.palette.primary.main,
	},
}));
export default StyledButton;

export const GhoastButton = styled(Button)(({ theme }) => ({
  fontFamily: 'inherit !important',
  border: `none !important`,
  '&:hover, &:focus': {
    fontWeight: '400 !important',
    backgroundColor: theme.palette.primary.light,
  },
}));
