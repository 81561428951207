import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';

// material-ui
import {
	Stack,
	FormControl,
	OutlinedInput,
	Grid,
	Select,
	MenuItem,
	InputAdornment,
	Tooltip,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	InputLabel,
	ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

// project imports
import { StyledButton as Button } from '../../components/button';
import { unProtectedFetch } from '../../utils/axios';
import {
	Tooltip as StyledTooltip,
	ToolipContent,
} from '../../components/contentTooltip';
import LoaderButton from 'src/components/loaderButton';
import config from 'src/config';

// assets
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import {
	isPasswordContainLowercaseLetters,
	isPasswordContainNumbers,
	isPasswordContainSpecialCharacters,
	isPasswordContainUppercaseLetters,
} from 'src/utils/passwordStrengthFinder';
import { token } from 'src/constants/api';

// ===========================|| FORM WIZARD - VALIDATION  ||=========================== //
const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email address').required('Email Required'),
	name: Yup.string().required('Full Name Required'),
	password: Yup.string().required('Password Required'),
	package: Yup.string().required('Package Required'),
});

const defaultValues = {
	email: '',
	name: '',
	password: '',
	countryCode: '#',
	phone: '',
	package: '#',
};

const Form = ({
	handleVerify,
	packages,
	isPackagesLoading,
	selectedPackage,
	isPackageDefaultSelect,
	setIsEmailVerified,
	isEmailVerified,
	verifiedEmail,
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const theme = useTheme();
	const handleTogglePassword = () => setShowPassword((prev) => !prev);
	const [showPasswordStrength, setShowPasswordStrength] = useState(false);

	const openPasswordStrength = () => setShowPasswordStrength(true);
	const closePasswordStrength = () => setShowPasswordStrength(false);

	const { data: tokens } = useQuery({
		queryKey: ['access-token'],
		queryFn: async () => {
			const domainName = window.location.hostname;
			// const tokenURL = token.getAccess.replace(/^\/+/g, '');
			const url = new URL(config.backendServiceBaseURL + token.getAccess);
			const { data } = await axios.post(url, { domainName });
			return data;
		},
	});

	const { isPending: isCreatingAccount, mutate: createCustomer } = useMutation({
		mutationKey: ['signUp'],
		mutationFn: async (data) => {
			const modifiedValues = { ...data };
			modifiedValues.packageId = selectedPackage;
			delete modifiedValues.package;
			modifiedValues.status = 'active';
			modifiedValues.createdBy = '';
			const response = await unProtectedFetch.post(
				`/customer`,
				modifiedValues,
				{
					headers: { Authorization: `Bearer ${tokens.accessToken}` },
				}
			);
			return response.data;
		},
		onSuccess: () => {
			toast.success('Account Created Successfully');
			window.open(config.ga_app_URL, '_self');
		},
	});

	// if there is a selected package, set it as default
	if (selectedPackage) {
		defaultValues.package = selectedPackage;
	}
	const formik = useFormik({
		initialValues: defaultValues,
		validationSchema,
		onSubmit: createCustomer,
		enableReinitialize: true,
	});

	const { errors, touched, values, handleSubmit, handleChange, handleBlur } =
		formik;
	const randomString = Math.random().toString(36).substring(7);
	const isFormValid =
		(values.email && values.name && values.password && isEmailVerified) ||
		isCreatingAccount;

	const passwordStrength = useMemo(() => {
		let strength = 0;
		let passwordAdjectives = {};
		if (isPasswordContainLowercaseLetters(values.password)) {
			strength += 1;
			passwordAdjectives = { ...passwordAdjectives, lowercase: true };
		}
		if (isPasswordContainUppercaseLetters(values.password)) {
			strength += 1;
			passwordAdjectives = { ...passwordAdjectives, uppercase: true };
		}
		if (isPasswordContainNumbers(values.password)) {
			strength += 1;
			passwordAdjectives = { ...passwordAdjectives, numbers: true };
		}
		if (isPasswordContainSpecialCharacters(values.password)) {
			strength += 1;
			passwordAdjectives = { ...passwordAdjectives, specialCharacters: true };
		}
		if (values.password.length >= 8) {
			strength += 1;
		}
		return {
			strength,
			length: values.password.length,
			...passwordAdjectives,
		};
	}, [values.password]);
	return (
		<form
			noValidate
			onSubmit={handleSubmit}
			autoComplete={randomString}
			style={{ padding: 0, position: 'relative', zIndex: 10 }}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormControl
						size='small'
						fullWidth
						error={Boolean(touched.package && errors.package)}
						sx={{ cursor: 'not-allowed' }}
					>
						<InputLabel htmlFor='outlined-adornment-package'>
							Select Packages *
						</InputLabel>
						<Select
							id='outlined-adornment-package'
							value={values.package}
							label='Select Package *'
							name='package'
							onBlur={handleBlur}
							onChange={handleChange}
							inputProps={{}}
							disabled
						>
							<MenuItem value='#'>Select Packages</MenuItem>
							{/* {isPackageDefaultSelect && (
								<MenuItem value='efcd6b7f-cf04-4a8c-bfc2-d778826bc0d2'>
									Free
								</MenuItem>
							)} */}
							{isPackagesLoading ? (
								<MenuItem value={''}>Loading...</MenuItem>
							) : (
								packages &&
								packages?.map((item) => (
									<MenuItem key={item._id} value={item._id}>
										{item.name}
									</MenuItem>
								))
							)}
						</Select>
						{/* {touched.package && errors.package && (
							<FormHelperText error id='standard-weight-helper-text-package'>
								{errors.package}
							</FormHelperText>
						)} */}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl
						size='small'
						fullWidth
						error={Boolean(touched.name && errors.name)}
					>
						<InputLabel htmlFor='outlined-adornment-name'>
							Full Name *
						</InputLabel>
						<OutlinedInput
							id='outlined-adornment-name'
							type='text'
							label='Full Name *'
							value={values.name}
							name='name'
							placeholder='Full Name'
							onBlur={handleBlur}
							onChange={handleChange}
							inputProps={{}}
						/>
						{/* {touched.name && errors.name && (
							<FormHelperText error id='standard-weight-helper-text-name'>
								{errors.name}
							</FormHelperText>
						)} */}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<Stack
						direction='row'
						alignItems={touched.email && errors.email ? 'flex-start' : 'center'}
						gap={1}
					>
						<FormControl
							size='small'
							fullWidth
							error={Boolean(touched.email && errors.email)}
						>
							<InputLabel htmlFor='outlined-adornment-email'>Email *</InputLabel>
							<OutlinedInput
								id='outlined-adornment-email'
								type='text'
								label='Email *'
								value={values.email}
								name='email'
								placeholder='Email'
								required
								onBlur={(e) => {
									const { value } = e.target;
									if (value !== verifiedEmail) {
										setIsEmailVerified();
									}
									handleBlur(e);
								}}
								onChange={handleChange}
								inputProps={{
									autoComplete: 'new-email',
								}}
								endAdornment={
									touched.email && (
										<InputAdornment position='end'>
											<Tooltip
												title={
													isEmailVerified
														? 'Email verified'
														: 'Please verify your email address'
												}
											>
												{isEmailVerified ? (
													<DoneIcon color='success' />
												) : (
													<ErrorOutlineOutlinedIcon color='error' />
												)}
											</Tooltip>
										</InputAdornment>
									)
								}
							/>
							{/* {touched.email && errors.email && (
								<FormHelperText error id='standard-weight-helper-text-email'>
									{errors.email}
								</FormHelperText>
							)} */}
						</FormControl>
						{!errors.email && values.email && !isEmailVerified && (
							<Button
								disabled={Boolean(touched.email && errors.email)}
								variant='outlined'
								onClick={() => handleVerify(values.email)}
							>
								Verify
							</Button>
						)}
					</Stack>
				</Grid>

				<Grid item xs={12}>
					<StyledTooltip>
						<FormControl
							size='small'
							fullWidth
							error={Boolean(
								touched.password &&
								errors.password &&
								touched.password &&
								passwordStrength.strength < 5
							)}
						>
							<InputLabel htmlFor='outlined-adornment-password'>Password *</InputLabel>
							<OutlinedInput
								id='outlined-adornment-password'
								type={showPassword ? 'text' : 'password'}
								value={values.password}
								name='password'
								label='password *'
								placeholder='Password'
								onFocus={openPasswordStrength}
								onBlur={(e) => {
									handleBlur(e);
									closePasswordStrength();
								}}
								onChange={handleChange}
								inputProps={{
									autoComplete: 'new-password',
								}}
								endAdornment={
									<InputAdornment position='end'>
										{touched.password &&
											(passwordStrength.strength < 5 ? (
												<ReportGmailerrorredIcon color='error' />
											) : (
												<DoneAllIcon color='success' />
											))}
										<IconButton
											size='small'
											aria-label='toggle password visibility'
											onClick={handleTogglePassword}
											edge='end'
											sx={{
												border: `1px solid transparent !important`,
												'&:hover': {
													color: `${theme.palette.grey[600]} !important`,
												},
											}}
										>
											{showPassword ? (
												<VisibilityOutlinedIcon />
											) : (
												<VisibilityOffOutlinedIcon />
											)}
										</IconButton>
									</InputAdornment>
								}
							/>
							{/* {touched.password && errors.password && (
							<FormHelperText error id='standard-weight-helper-text-password'>
								{errors.password}
							</FormHelperText>
						)} */}
						</FormControl>
						<ToolipContent show={showPasswordStrength}>
							{/* Hello there */}
							{/* <Typography variant='subtitle1' fontWeight='400'>
								Password must contain:
							</Typography> */}
							<List dense>
								<ListItem
									dense
									disableGutters
									disablePadding
									style={{
										color: passwordStrength.length >= 8 ? 'green' : 'red',
									}}
								>
									<ListItemIcon sx={{ minWidth: 40 }}>
										{passwordStrength.length >= 8 ? (
											<DoneAllIcon color='success' />
										) : (
											<ReportGmailerrorredIcon color='error' />
										)}
									</ListItemIcon>
									<ListItemText primary='8 characters or more' />
								</ListItem>

								<ListItem
									dense
									disableGutters
									disablePadding
									style={{
										color: passwordStrength.numbers ? 'green' : 'red',
									}}
								>
									<ListItemIcon sx={{ minWidth: 40 }}>
										{passwordStrength.numbers ? (
											<DoneAllIcon color='success' />
										) : (
											<ReportGmailerrorredIcon color='error' />
										)}
									</ListItemIcon>
									<ListItemText primary='at least one number' />
								</ListItem>

								<ListItem
									dense
									disableGutters
									disablePadding
									style={{
										color: passwordStrength.uppercase ? 'green' : 'red',
									}}
								>
									<ListItemIcon sx={{ minWidth: 40 }}>
										{passwordStrength.uppercase ? (
											<DoneAllIcon color='success' />
										) : (
											<ReportGmailerrorredIcon color='error' />
										)}
									</ListItemIcon>
									<ListItemText primary='one uppercase letter' />
								</ListItem>

								<ListItem
									dense
									disableGutters
									disablePadding
									style={{
										color: passwordStrength.lowercase ? 'green' : 'red',
									}}
								>
									<ListItemIcon sx={{ minWidth: 40 }}>
										{passwordStrength.lowercase ? (
											<DoneAllIcon color='success' />
										) : (
											<ReportGmailerrorredIcon color='error' />
										)}
									</ListItemIcon>
									<ListItemText primary='one lowercase letter' />
								</ListItem>

								<ListItem
									dense
									disableGutters
									disablePadding
									style={{
										color: passwordStrength.specialCharacters ? 'green' : 'red',
									}}
								>
									<ListItemIcon sx={{ minWidth: 40 }}>
										{passwordStrength.specialCharacters ? (
											<DoneAllIcon color='success' />
										) : (
											<ReportGmailerrorredIcon color='error' />
										)}
									</ListItemIcon>
									<ListItemText primary='special characters' />
								</ListItem>
							</List>
						</ToolipContent>
					</StyledTooltip>
				</Grid>

				<Grid item xs={12}>
					<LoaderButton
						disabled={
							!Boolean(isFormValid) ||
							isCreatingAccount ||
							passwordStrength.strength < 5
						}
						fullWidth
						variant='contained'
						type='submit'
						sx={{ '&:hover': { fontWeight: '400 !important' } }}
						isLoading={isCreatingAccount}
					>
						Sign Up
					</LoaderButton>
				</Grid>
			</Grid>
		</form>
	);
};

export default Form;
